import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styles from './ProductCarousel.module.css';

export default function ProductCarousel({ imgPaths }) {
  return (
    <Carousel pause="hover" touch variant="dark">
      {imgPaths.map((imgPath, index) => (
        <Carousel.Item key={index}>
          <img src={imgPath} alt={`Slide ${index}`} className={styles.img} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
