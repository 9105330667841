import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import styles from './HomePage.module.css';
import Brand from './brand/Brand';
import Footer from 'components/footer/Footer';
import ProductCarousel from 'components/product-carousel/ProductCarousel';
import VSpacer from 'components/spacers/VSpacer';
import { productList, homePageImgPaths } from 'data/product-list';
import { Array } from 'utils/array';
import { Link } from 'react-router-dom';

// const imgPaths = Array.shuffle(
//   productList.map((product) => product.imgPaths[0])
// );

export default function HomePage() {
  return (
    <>
      <header className={styles.header} >
        <Navbar showLogo={false} />
        <Brand />
      </header>
      <VSpacer height={40} />
      <Link to="/products">
        <div className={styles.carouselContainer}>
          <ProductCarousel imgPaths={homePageImgPaths} />
        </div>
      </Link>
      <VSpacer height={40} />
      <Footer />
    </>
  );
}
