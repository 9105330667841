import React, { useEffect } from 'react';
import styles from './ProductsPage.module.css';
import ProductList from './components/product-list/ProductList';
import Footer from 'components/footer/Footer';
import VSpacer from 'components/spacers/VSpacer';
import Navbar from 'components/navbar/Navbar';
import Header from 'components/header/Header';
import bg from 'images/products/d/d1.jpg';

export default function ProductsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={styles.container} id="products">
      <VSpacer height={20} />
      <Navbar />
      <Header title="Custom Wooden Art" bg={bg} />
      <div style={{ height: '35px' }}></div>
      <ProductList />
      <VSpacer height={40} />
      <Footer />
    </main>
  );
}
