import React from 'react';
import ReactDOM from 'react-dom';
import styles from './Product.module.css';
import ProductModal from '../../pages/products-page/components/product-modal/ProductModal';
import { Link } from 'react-router-dom';

export default function Product({ key, productData }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [img1, img2, img3, img4] = productData.imgPaths;

  function showModal() {
    setModalShow(true);
  }

  function hideModal() {
    setModalShow(false);
  }

  return (
    <>
      <section key={key} className={styles.container} onClick={showModal}>
        <Link
          to={`/products/${productData.name}`}
          className={styles.productName}
        >
          <h2>{productData.name}</h2>
        </Link>
        <div className={styles.imagesContainer}>
          <img
            src={img1}
            className={styles.col1}
            loading="lazy"
            alt="First Product"
          />
          <div className={styles.col2}>
            <img
              src={img2}
              className={styles.stackedImg}
              loading="lazy"
              alt="Second Product"
            />
            <img
              src={img3}
              className={styles.stackedImg}
              loading="lazy"
              alt="Third Product"
            />
          </div>
          <img
            src={img4}
            className={styles.col3}
            loading="lazy"
            alt="Fourth Product"
          />
        </div>
      </section>
      {ReactDOM.createPortal(
        <ProductModal
          show={modalShow}
          onHide={hideModal}
          productData={productData}
        />,
        document.getElementById('product-modal')
      )}
    </>
  );
}
