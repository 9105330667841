import React, { useEffect } from 'react';
import styles from './ProductDetailPage.module.css';
import ProductCarousel from 'components/product-carousel/ProductCarousel';
import ContactForm from 'components/contact-form/ContactForm';
import { useParams } from 'react-router-dom';
import { getProductByName } from 'data/product-list';
import Navbar from 'components/navbar/Navbar';
import VSpacer from 'components/spacers/VSpacer';
import Footer from 'components/footer/Footer';

export default function ProductDetailPage() {
  const urlParams = useParams();
  const productName = urlParams.productName;
  const product = getProductByName(productName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className={styles.container}>
      <Navbar />
      <VSpacer height={20} />
      <section className={styles.productContainer}>
        <div className={styles.carouselCol}>
          <ProductCarousel imgPaths={product.imgPaths} />
        </div>

        <article className={styles.infoCol}>
          <h2>{product.name}</h2>
          {/* <p>{product.description} </p> */}
          <p>
          Step into a world where art meets innovation with our distinctive 
          collection of parametric wall art designs. Each piece is a 
          testament to craftsmanship, meticulously curated to bring a touch of 
          elegance and sophistication to any space. Explore a myriad of captivating 
          patterns and intricate details that transcend conventional art forms, 
          offering a unique blend of style and imagination. 
          Elevate your décor with a statement piece that sparks conversation and 
          adds a timeless charm to your home or office environment.
          </p>
        </article>
        <div className={styles.formCol}>
          <ContactForm
            showHeader={false}
            formClass={styles.formClass}
            formInputClass={styles.formInputClass}
            defaultMessage={`I am interested in this product (${productName}). Please provide me with more details.`}
          />
        </div>
      </section>
      <VSpacer height={40} />
      <Footer />
    </main>
  );
}
