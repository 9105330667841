import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ProductCarousel from '../../../../components/product-carousel/ProductCarousel';
import styles from './ProductModal.module.css';
import { Link } from 'react-router-dom';

export default function ProductModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName={styles.modal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <Link
            to={`/products/${props.productData.name}`}
            className={styles.link}
          >
            {props.productData.name}
          </Link>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductCarousel imgPaths={props.productData.imgPaths} />
      </Modal.Body>
    </Modal>
  );
}
