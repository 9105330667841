import React from 'react';
import Product from '../../../../components/product/Product';
import styles from './ProductList.module.css';
import { productList } from 'data/product-list';

export default function ProductList() {
  return (
    <main className={styles.productListContainer}>
      {productList.map((product) => (
        <Product key={product.name} productData={product} />
      ))}
    </main>
  );
}
