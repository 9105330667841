import React, { useState } from 'react';
import styles from './ContactForm.module.css';

export default function ContactForm({
  showHeader = true,
  formClass = '',
  formInputClass = '',
  defaultMessage = '',
}) {
  const [message, setMessage] = useState(defaultMessage);
  const inputClass = `${styles.input} ${formInputClass}`;
  const FORM_API_KEY = "f9bc80c1-95f2-47b5-a91a-c631d16d182b";
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div
          className={styles.headerCol}
          style={{ display: showHeader ? 'block' : 'none' }}
        >
          <div className={styles.pContainer}>
            <p>LET'S DISCUSS</p>
            <p>YOUR CUSTOM</p>
            <p>DESIGN</p>
          </div>
        </div>
        <div className={`${styles.formCol} ${formClass}`}>
          <form action="https://api.web3forms.com/submit" method="POST">
            <input type="hidden" name="access_key" value={FORM_API_KEY} />

            <div className={`${styles.nameRow} ${formClass}`}>
              <input
                type="text"
                name="name"
                placeholder="First Name*"
                className={inputClass}
              />
              <input
                type="text"
                name="surname"
                placeholder="Last Name*"
                className={inputClass}
              />
            </div>

            <input
              type="tel"
              name="tel"
              placeholder="Phone*"
              className={inputClass}
            />
            <input
              type="email"
              name="email"
              placeholder="Email*"
              className={inputClass}
            />
            <textarea
              type="text"
              name="message"
              rows={8}
              placeholder="Message*"
              className={inputClass}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <button
              type="submit"
              className={`${inputClass} ${styles.submitBtn}`}
            >
              Request a Quote
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
