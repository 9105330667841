import React from 'react';
import styles from './Brand.module.css';
import logo2_cropped from '../../../images/logo2-cropped.png';

export default function Brand() {
  return (
    <div className={styles.brand}>
      <img src={logo2_cropped} alt="Website Logo" />
      <p>CUSTOM | DESIGN </p>
      <h1>CUSTOM WOODEN ART</h1>
      <div className={styles.contact}>
        <a href="/#contact" className={styles.navbarLink} >CONTACT US</a>
        <div className={styles.triangleUp}></div>
        <div className={styles.triangleDown}></div>
      </div>
    </div>
  );
}
