import React from 'react';
import styles from './ContactPage.module.css';
import ContactForm from '../../components/contact-form/ContactForm';
import VSpacer from '../../components/spacers/VSpacer';
import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar/Navbar';
import Header from 'components/header/Header';
import contact_bg from 'images/contact_bg.jpg';

export default function ContactPage() {
  return (
    <main className={styles.container} id="contact">
      <VSpacer height={20} />
      <Navbar />
      <Header title="CONTACT US" bg={contact_bg} />
      <VSpacer height={120} />
      <ContactForm />
      <VSpacer height={60} />
      <Footer />
    </main>
  );
}
