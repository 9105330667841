import React from 'react';
import styles from './Footer.module.css';
import named_logo from '../../images/named_logo.png';
import { IoLocation } from 'react-icons/io5';
import { BsTelephoneFill } from 'react-icons/bs';
import { BsPinterest } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';

export default function Footer() {
  return (
    <footer className={styles.container}>
      <div className={styles.bg}></div>
      <div className={styles.firstRow}>
        <div className={styles.firstCol}>
          <img src={named_logo} className={styles.img} alt="Logo" />
          <p>
            Let us bring your vision to life, crafting bespoke designs that stand as timeless testaments to your unique perspective.
          </p>
        </div>
        <div className={styles.secondCol}>
          <h3>CONTACT</h3>
          <div className={styles.secondCol__secondHalf}>
            <div className={styles.addressContainer}>
              <IoLocation className={styles.locationIcon} />
              <address>
                USA, Cumming/Georgia
              </address>
            </div>
            <div className={styles.phoneContainer}>
              <BsTelephoneFill className={styles.telephoneIcon} />
              <phone>
                (+1) 404 514-2898
              </phone>
            </div>
          </div>
        </div>
        <div className={styles.thirdCol}>
          <h3>SOCIAL</h3>
          <div className={styles.iconContainer}>
            <a
              href="https://www.pinterest.com/customwoodenart/"
              target="_blank"
              rel="noreferrer"
            >
              <BsPinterest style={{ color: '#814f22' }} />
            </a>
            <a
              href="https://www.instagram.com/customwoodenart/"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram style={{ color: '#814f22' }} />
            </a>
            <a href="https://www.facebook.com/customwoodenartt" target="_blank" rel="noreferrer">
              <BsFacebook style={{ color: '#814f22' }} />
            </a>
          </div>
        </div>
      </div>
      <p className={`${styles.secondRow}`}></p>
    </footer>
  );
}
