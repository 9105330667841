import React from 'react';
import styles from './Header.module.css';

export default function Header({ title, bg }) {
  return (
    <section className={styles.headerContainer}>
      <div className={styles.imgContainer}>
        <div className={styles.overlay}></div>
        <img src={bg} className={styles.img} loading="lazy" alt="background" />
        <h1 className={styles.title}>{title}</h1>
      </div>
    </section>
  );
}
