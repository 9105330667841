import React from 'react';
import styles from './Navbar.module.css';
import { Link } from 'react-router-dom';
import named_logo from 'images/named_logo.png';

export default function Navbar({ showLogo = true }) {
  return (
    <navbar className={styles.navbar}>
      <Link to="/products" className={styles.navbarLink}>
        Designs
      </Link>
      <Link to="/" style={{ visibility: showLogo ? 'block' : 'hidden' }}>
        <img src={named_logo} alt="logo" className={styles.logo} />
      </Link>
      <Link to="/contact" className={styles.navbarLink}>
        Contact
      </Link>
    </navbar>
  );
}
