import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import HomePage from './pages/home-page/HomePage';
import ProductsPage from 'pages/products-page/ProductsPage';
import ContactPage from 'pages/contact-page/ContactPage';
import ProductDetailPage from 'pages/product-detail-page/ProductDetailPage';

const router = createBrowserRouter([
  { path: '/', element: <HomePage /> },
  { path: '/products', element: <ProductsPage /> },
  { path: '/contact', element: <ContactPage /> },
  { path: '/products/:productName', element: <ProductDetailPage /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
